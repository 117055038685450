.videoanimation {
  opacity: 1 !important;
  -webkit-transition: opacity 0.6s ease-in;
  -moz-transition: opacity 0.6s ease-in;
  -ms-transition: opacity 0.6s ease-in;
  -o-transition: opacity 0.6s ease-in;
  transition: opacity 0.6s ease-in;
}

.blur {
  -webkit-animation: blur 9s;
  -moz-animation: blur 9s;
  -ms-animation: blur 9s;
  -o-animation: blur 9s;
  animation: blur 9s;
}

@keyframes blur {
  0% {
    filter: blur(0px);
  }
  15% {
    filter: blur(1.5px);
  }
  80% {
    filter: blur(1.5px);
  }
  100% {
    filter: blur(0px);
  }
}

/*
@keyframes opacityy {
  0%    { opacity: 0; }
  100%  { opacity: 1; }
}
*/

#video {
  position: absolute;
  width: 410px;
  height: 410px;
  top: -2px;
  left: -3px;
}

@font-face {
  font-family: "GilroyLight"; /*Can be any text*/
  src: local("GilroyLight"), url("./Fonts/GilroyLight.woff") format("woff");
}

@font-face {
  font-family: "GilroyBold"; /*Can be any text*/
  src: local("GilroyExtraBold"),
    url("./Fonts/GilroyExtraBold.woff") format("woff");
}

button {
  font-family: "GilroyBold";
}


h1 {
  color: black;
  font-size: 32px;
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
  font-family: "GilroyLight";
  margin-block-start: 0.6em;
  margin-block-end: -0.2em;
}

h2 {
  color: black;
  font-size: 32px;
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
  margin-bottom: 50px;
  font-family: "GilroyBold";
  margin-block-end: 1em;
  margin-block-start: 0.4em;
}

.light{
  font-family: "GilroyLight";

}

a {
  margin-top: 30px;
  font-family: "GilroyLight";
  color: black;
  text-decoration: green underline;
  text-underline-offset: 4px;
}


/*Other*/
body {
  background: #d5d5d5;
}

#icon {
  width: 77px;
}

.button {
  left: -4%;
  position: relative;
  top: 68px;
  display: block;
  width: 340px;
  margin: 20px auto;
  cursor: pointer;
  background-color: initial;
  background-image: linear-gradient(#898989, #595959);
  border-radius: 8px;
  border-width: 0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  font-size: 30px;

  height: 50px;
  justify-content: center;
}

.button:hover {
  opacity: 0.8;
}
.button.disabled {
  opacity: 0.6;
}

.foot {
  position: absolute;
  top: 528px;
  left: 48%;
  margin-top: -205px;
  margin-left: -205px;
  width: 80%;
}

.display {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 50px;
  border: 1px solid black;
  border-radius: 20px;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 2rem;
  margin: 20px auto;
}

.marker {
  position: absolute;
  width: 43px;
  left: 190px;
  top: 4px;
  z-index: 2;
}

.wheel {
  width: 90%;
  height: 90%;
  position: relative;
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  backface-visibility: hidden;
  perspective: 1000;
}

#outer {
  position: relative;
}

#app {
  position: absolute;
  top: 200px;
  left: 50%;
  margin-top: -237px;
  margin-left: -237px;
  width: 474px;
  height: 474px;
}

#web-cam {
  position: absolute;
  top: 199.2px;
  left: 49.9%;
  margin-top: -205px;
  margin-left: -205px;
  width: 366px;
  height: 366px;
  border-radius: 325px;
  -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
  mask-image: radial-gradient(circle, white 100%, black 100%);
}

.shadow {
  background-image: radial-gradient(
    closest-side,
    transparent,
    transparent 96%,
    rgba(37, 37, 37, 0.345)
  );
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

@media screen and (max-width: 374px) and (max-height: 575px) {
  h1 {
    font-size: 16px;
    margin-block-end: 0em;
    margin-block-start: 0.5em;
  }

  h2 {
    font-size: 16px;
    margin-block-end: 2.5em;
  }

  #video {
    top: -5px;
    width: 270px;
    height: 270px;
    overflow-x: hidden;
    visibility: hidden;
  }

  #web-cam {
    top: 162px;
    left: 59.5%;
    margin-top: -186px;
    margin-left: -166px;
    width: 264px;
    height: 264px;
  }

  #app {
    top: 170px;
    left: 60%;
    margin-top: -215px;
    margin-left: -190px;
    width: 340px;
    height: 340px;
  }

  .foot {
    top: 435px;
    left: 65%;
  }

  #icon {
    width: 50px;
  }

  .button {
    top: 43px;
    overflow-x: hidden;
    width: 250px;
    font-size: 20px;
    height: 37px;
  }

  .marker {
    left: 136.6px !important;
    top: 2px;
    width: 33px;
  }

  .wheel {
    overflow-x: hidden !important;
    will-change: transform;
  }

  .wheel::after {
    overflow-x: hidden !important;
  }

  html,
  body {
    overflow-x: hidden;
    position: relative;
    height: 100%;
  }

  #outer {
    margin-left: 10px;
  }
}
@media screen and (min-width: 335px) and (max-width: 390px) {
  h1 {
    font-size: 19px;
    margin-block-end: -0.2em;
    margin-block-start: 0.5em;
  }

  h2 {
    font-size: 19px;
    margin-block-end: 2.5em;
  }
  #video {
    top: -5px;
    width: 270px;
    height: 270px;
    overflow-x: hidden;
  }

  #web-cam {
    top: 162px;
    left: 59.5%;
    margin-top: -186px;
    margin-left: -166px;
    width: 264px;
    height: 264px;
  }

  #app {
    top: 170px;
    left: 60%;
    margin-top: -215px;
    margin-left: -190px;
    width: 340px;
    height: 340px;
  }
  .foot {
    top: 435px;
    left: 65%;
  }

  #icon {
    width: 77px;
  }

  .button {
    top: 43px;
    overflow-x: hidden;
    width: 300px;
    font-size: 24px;
  }

  .marker {
    left: 135px;
    top: 1px;
    width: 36px;
  }

  .wheel {
    overflow-x: hidden !important;
    will-change: transform;
  }

  .wheel::after {
    overflow-x: hidden !important;
  }

  html,
  body {
    overflow-x: hidden;
    position: relative;
    height: 100%;
  }
}

@media screen and (max-width: 425px) and (min-width: 390px) {
  h1 {
    font-size: 27px;
    margin-block-end: -0.2em;
  }

  h2 {
    font-size: 27px;
    margin-block-end: 1.8em;
  }
  #video {
    top: 1px;
    width: 295px;
    height: 295px;
    left: -1px;
    overflow-x: hidden;
  }

  #web-cam {
    top: 170px;
    left: 55%;
    margin-top: -186px;
    margin-left: -166px;
    width: 294px;
    height: 294px;
  }

  #app {
    top: 170px;
    left: 55%;
    margin-top: -210px;
    margin-left: -190px;
    width: 380px;
    height: 380px;
  }

  .foot {
    top: 475px;
    left: 60%;
    overflow-x: hidden;
  }

  #icon {
    width: 80px;
  }

  .button {
    top: 60px;
    overflow-x: hidden;
    font-size: 25px;
    height: 55px;
    width: 290px;
  }

  .marker {
    left: 151px;
    top: 2px;
    width: 40px;
  }

  .wheel {
    overflow-x: hidden !important;
    will-change: transform;
  }

  .wheel::after {
    overflow-x: hidden !important;
  }

  html,
  body {
    overflow-x: hidden;
    position: relative;
    height: 100%;
  }
}

@media screen and (max-width: 768px) and (min-width: 425px) {
  h1 {
    font-size: 27px;
    margin-block-end: -0.4em;
  }

  h2 {
    font-size: 27px;
    margin-block-end: 1.3em;
  }

  #video {
    top: 1px;
    width: 345px;
    height: 345px;
    left: 0px;
    overflow-x: hidden;
  }
  #web-cam {
    top: 170px;
    left: 55%;
    margin-top: -184px;
    margin-left: -192px;
    width: 340px;
    height: 340px;
  }

  #app {
    top: 170px;
    left: 55%;
    margin-top: -210px;
    margin-left: -220px;
    width: 440px;
    height: 440px;
  }

  .foot {
    top: 505px;
    left: 52%;
    overflow-x: hidden;
  }

  #icon {
    width: 80px;
  }

  .button {
    top: 80px;
    overflow-x: hidden;
    font-size: 27px;
    height: 60px;
    width: 300px;
  }

  .marker {
    left: 178px;
    top: 2px;
    width: 40px;
  }

  .wheel {
    overflow-x: hidden !important;
    will-change: transform;
  }

  .wheel::after {
    overflow-x: hidden !important;
  }

  html,
  body {
    overflow-x: hidden;
    position: relative;
    height: 100%;
  }
}

@media screen and (min-width: 885px) and (max-width: 1024px) and (min-height: 1200px) {
  #app {
    top: 400px;
    left: 54%;
    margin-top: -425px;
    margin-left: -425px;
    width: 850px;
    height: 850px;
  }

  #web-cam {
    top: 215px;
    left: 46.7%;
    margin-top: -185px;
    margin-left: -305px;
    width: 660px;
    height: 660px;
  }
  .foot {
    top: 800px;
    left: 29%;
  }
  .button {
    top: 165px;
    width: 500px;
    font-size: 40px;
    height: 90px;
  }

  #icon {
    width: 130px;
  }
  .marker {
    left: 346px;
    top: 5px;
    width: 70px;
  }

  #video {
    width: 655px;
    height: 655px;
    top: 1px !important;
    left: 0px;
  }
}

@media screen and (min-width: 768px) and (max-width: 824px) {
  h1 {
    font-size: 30px;
    margin-block-end: -0.2em;
    margin-block-start: 0.2em;
  }
  h2 {
    font-size: 30px;
    margin-block-end: 1em;
  }
  #app {
    top: 320px;
    left: 55%;
    margin-top: -350px;
    margin-left: -350px;
    width: 700px;
    height: 700px;
  }

  #web-cam {
    top: 200px;
    left: 55.2%;
    margin-top: -185px;
    margin-left: -307px;
    width: 541px;
    height: 541px;
  }
  .foot {
    top: 680px;
    left: 34%;
  }
  .button {
    top: 105px;
    width: 450px;
    font-size: 34px;
    height: 55px;
    left: -5%;
  }

  #icon {
    width: 90px;
  }
  .marker {
    left: 290px;
    top: 5px;
    width: 50px;
  }

  #video {
    width: 542px;
    height: 542px;
    top: 1px !important;
    left: -1px;
  }
}

@media screen and (min-width: 768px) and (min-height: 1028px) {
  h1 {
    font-size: 43px;
    margin-block-end: -0.2em;
  }
  h2 {
    font-size: 43px;
  }

  #app {
    top: 320px;
    left: 55%;
    margin-top: -350px;
    margin-left: -350px;
    width: 700px;
    height: 700px;
  }

  #web-cam {
    top: 200px;
    left: 55.2%;
    margin-top: -185px;
    margin-left: -305px;
    width: 541px;
    height: 541px;
  }
  .foot {
    top: 680px;
    left: 34%;
  }
  .button {
    top: 115px;
    width: 450px;
    font-size: 40px;
    height: 75px;
    left: -5%;
  }

  #icon {
    width: 100px;
  }
  .marker {
    left: 290px;
    top: 5px;
    width: 50px;
  }

  #video {
    width: 540px;
    height: 540px;
    top: 1px !important;
    left: -1px;
  }
}

@media screen and (min-width: 1080px) and (min-height: 1028px) {
  h1 {
    font-size: 53px;
    margin-block-end: -0.2em;
  }
  h2 {
    font-size: 53px;
    margin-block-end: 0.7em;
  }
  #app {
    top: 200px;
    left: 50.9%;
    margin-top: -220px;
    margin-left: -350px;
    width: 700px;
    height: 700px;
  }

  #web-cam {
    top: 211px;
    left: 50.9%;
    margin-top: -185px;
    margin-left: -305px;
    width: 539px;
    height: 539px;
  }
  .foot {
    top: 700px;
    left: 34%;
  }
  .button {
    top: 125px;
    width: 400px;
    font-size: 40px;
    height: 70px;
  }

  #icon {
    width: 100px;
  }
  .marker {
    left: 285px;
    top: 5px;
    width: 60px;
  }

  #video {
    width: 540px;
    height: 540px;
    top: 1px !important;
    left: -1px;
  }
}

@media screen and (min-width: 1580px) and (min-height: 1250px) {
  #app {
    top: 430px;
    left: 50%;
    margin-top: -470px;
    margin-left: -470px;
    width: 900px;
    height: 900px;
  }
  #web-cam {
    top: 208px;
    left: 50%;
    margin-top: -185px;
    margin-left: -408px;
    width: 683px;
    height: 683px;
  }

  .foot {
    top: 830px;
    left: 27%;
  }
  .button {
    top: 175px;
    width: 550px;
    font-size: 40px;
    height: 115px;
  }

  #icon {
    width: 150px;
  }
  .marker {
    left: 364px;
    top: 5px;
    width: 80px;
  }

  #video {
    width: 695px;
    height: 695px;
    top: 1px !important;
    left: 0px;
  }
}

@media screen and (min-width: 1030px) and (min-height: 700px) and (max-height: 720px) and (max-width: 1280px) {
  h1 {
    font-size: 28px;
    margin-block-end: -0.2em;
  }
  h2 {
    font-size: 28px;
    margin-block-end: 2em;
  }
  #app {
    top: 430px;
    left: 50%;
    margin-top: -490px;
    margin-left: -250px;
    width: 500px;
    height: 500px;
  }
  #web-cam {
    top: 208px;
    left: 50%;
    margin-top: -236px;
    margin-left: -218px;
    width: 390px;
    height: 390px;
  }

  .foot {
    top: 540px;
    left: 45%;
  }
  .button {
    top: 75px;
    width: 330px;
    font-size: 30px;
    height: 57px;
  }

  #icon {
    width: 70px;
  }
  .marker {
    left: 204px;
    top: 4px;
    width: 40px;
  }

  #video {
    width: 695px;
    height: 695px;
    top: 1px !important;
    left: 0px;
  }
}
